@font-face {
  font-family: "Regola Pro";
  src: url("./assets/fonts/RegolaPro-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Regola Pro";
  src: url("./assets/fonts/RegolaPro-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Regola Pro", sans-serif;
}
