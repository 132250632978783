.App {
  height: 100vh;
  max-height: 100svh;
  max-width: 100%;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f0efe7;
}
/* body {
  position: relative;
} */

.adu-fadein-animation {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.map-search-select .ant-select-selector {
  border-radius: 9px !important;
}
.map-search-select .ant-select-arrow,
.map-search-select .ant-select-clear {
  top: 40% !important;
}

.aduchat-image-selection-loader {
  width: 55px;
  aspect-ratio: 1;
  --g1: conic-gradient(from 90deg at top 3px left 3px, #0000 90deg, #826dfb 0);
  --g2: conic-gradient(
    from -90deg at bottom 3px right 3px,
    #0000 90deg,
    #826dfb 0
  );
  background: var(--g1), var(--g1), var(--g1), var(--g1), var(--g2), var(--g2),
    var(--g2), var(--g2);
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-size: 25px 25px;
  background-repeat: no-repeat;
  animation: l11 1.5s infinite;
}
@keyframes l11 {
  0% {
    background-size: 35px 15px, 15px 15px, 15px 35px, 35px 35px;
  }
  25% {
    background-size: 35px 35px, 15px 35px, 15px 15px, 35px 15px;
  }
  50% {
    background-size: 15px 35px, 35px 35px, 35px 15px, 15px 15px;
  }
  75% {
    background-size: 15px 15px, 35px 15px, 35px 35px, 15px 35px;
  }
  100% {
    background-size: 35px 15px, 15px 15px, 15px 35px, 35px 35px;
  }
}

.input-search-select .ant-select-selector {
  padding-left: 0 !important;
}
.input-search-select .ant-select-selector .ant-select-selection-search {
  left: 0 !important;
}

.bedbath-select .ant-select-selector {
  border-radius: 9px !important;
}

@media (min-width: 768px) {
  .input-search-select {
    margin: 0.8rem 4rem 0.8rem 2rem !important;
    padding: 0 !important;
  }
}

.adu-chat-card-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
@media (max-width: 767px) {
  .input-search-select {
    margin: 0.8rem 2.5rem 0.8rem 1rem !important;
    padding: 0 !important;
  }

  .aduchat-send-btn {
    right: 0.5rem;
  }
  .adu-chat-card-avatar {
    width: 35px;
    height: 35px;
  }
}

.input-search-select .ant-select-selector {
  border: none !important;
}
.input-search-select .ant-select-selection-placeholder,
.input-search-select .ant-select-selection-item {
  text-align: start !important;
}

.adu-chat-gradient-border {
  border-radius: 8px;
  border: 1px solid transparent !important;
  background: linear-gradient(to right, white, white),
    linear-gradient(
      to right,
      rgba(255, 194, 0, 1),
      rgba(245, 166, 35, 1),
      rgba(150, 93, 247, 1),
      rgba(109, 122, 251, 1)
    ) !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
  transition: background 2s ease-in-out !important;
}

.aduchat-typing-loader {
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #504d3f 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: typing 1s infinite linear;
}

@media screen and (max-width: 768px) {
  .aduchat-typing-loader {
    width: 30px;
  }
}

@media screen and (min-width: 769px) {
  .aduchat-typing-loader {
    width: 40px;
  }
}

@keyframes typing {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }
  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }
  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}

.pac-item {
  font-size: 1em;
}
.pac-matched {
  font-size: 1.2em;
}
.pac-target-input {
  font-size: 1em;
}
.pac-item:hover {
  font-weight: bold;
}
.pac-container {
  animation: rotateMenu 400ms ease-in-out forwards;
  transform-origin: top center;
}

@-moz-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@-webkit-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@-o-keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

.adu-chat-header {
  display: flex;
  align-items: center;
  color: #504d3f;
  text-align: start;
  font-size: 24px;
  font-weight: 600;
  height: 76px;
  width: 100%;
  background-color: #fbf9f7;
  box-shadow: 0px 0px 25px 0px #c1c1c140;
  backdrop-filter: blur(10px);
  padding-left: 1rem;
}

.ant-btn {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0.05) 100%
  ) !important;
  color: #504d3f !important;
  border: 1px solid #dbdbdb !important;
  box-shadow: 0px 0px 25px 0px #c1c1c140 !important;
  border-radius: 12;
}
.ant-btn:hover {
  border: 1px solid transparent !important;
  background: linear-gradient(to right, #f0efe7, #f0efe7),
    linear-gradient(
      90.55deg,
      #ffc200 -3.45%,
      #f5a623 24.53%,
      #965df7 63.27%,
      #6d7afb 104.16%
    ) !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
  box-shadow: 0px 0px 25px 0px #c1c1c140;
}
@media screen and (min-width: 769px) {
  .btn-gradient-border.ant-btn {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.05) 100%
    ) !important;
    color: #504d3f !important;
    border: 1px solid #dbdbdb !important;
    box-shadow: 0px 0px 25px 0px #c1c1c140 !important;
    padding: 10px 20px !important;
    border-radius: 40px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .calendly-btn.ant-btn {
    background: #6d7afb !important;
    color: white !important;
    border: 1px solid #6d7afb !important;
    padding: 10px 20px !important;
    border-radius: 40px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
}

@media screen and (max-width: 768px) {
  .btn-gradient-border.ant-btn {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.25) 0%,
      rgba(255, 255, 255, 0.05) 100%
    ) !important;
    color: #504d3f !important;
    border: 1px solid #dbdbdb !important;
    box-shadow: 0px 0px 25px 0px #c1c1c140 !important;
    padding: 8px 15px !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .calendly-btn.ant-btn {
    background: #6d7afb !important;
    color: white !important;
    border: 1px solid #6d7afb !important;
    box-shadow: 0px 0px 25px 0px #c1c1c140 !important;
    padding: 8px 15px !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
.btn-gradient-border.ant-btn:hover {
  border: 1px solid transparent !important;
  background: linear-gradient(182.18deg, #f0efe7, #f0efe7),
    linear-gradient(
      90.55deg,
      #ffc200 -3.45%,
      #f5a623 24.53%,
      #965df7 63.27%,
      #6d7afb 104.16%
    ) !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
  box-shadow: 0px 0px 25px 0px #c1c1c140;
}

.btn-gradient-border.ant-btn:focus-within {
  background: #d6d5d1 !important;
  border: 1px solid #dbdbdb !important;
  box-shadow: 0px 0px 25px 0px #c1c1c140 !important;
}

.calendly-btn.ant-btn:hover {
  scale: 1.05;
  transition: all 0.1s linear;
}

/* added flat */
@media screen and (max-width: 769px) {
  .btn-gradient-border-flat.ant-btn {
    background: #6d7afb !important;
    color: #504d3f !important;
    border: 1.5px solid #c7c2e7 !important;
    padding: 8px 15px !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}
@media screen and (max-width: 768px) {
  .btn-gradient-border-flat.ant-btn {
    background: #6d7afb !important;
    color: #504d3f !important;
    border: 1.5px solid #c7c2e7 !important;
    padding: 8px 15px !important;
    border-radius: 20px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
}

.btn-gradient-border-flat.ant-btn:hover {
  border: 1.5px solid transparent !important;

  background: #6d7afb !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
  box-shadow: 0px 0px 25px 0px #c1c1c140;
}

.btn-gradient-border-flat.ant-btn:focus-within {
  color: #ffffff !important;
  border: 1.5px solid #6d7afb !important;
  background: #6d7afb !important;
  box-shadow: 0px 0px 25px 0px #c1c1c140;
}

.pac-container .pac-item > span {
  color: #504d3f;
}
.pac-container {
  padding: 1rem;
  border-radius: 20px;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 16px;

  /* Set the width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #504d3f;
  /* Set the color of the thumb (scrollbar handle) */
  background-clip: content-box;
  border: 5px solid transparent;
  border-radius: 28px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #dbdbdb55;
  /* Set the color of the track (the area behind the thumb) */
  border-radius: 28px !important;
  background-clip: content-box;
  border: 5px solid transparent;
}

#lightboxBackdrop figure img {
  min-height: 400px;
  scale: 1.5;
}

.eye-btn:hover {
  scale: 1.2;
  transition: all 0.1s linear;
}

@media screen and (min-width: 769px) {
  .iframe-container {
    height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .iframe-container {
    height: 300px;
  }
}

@media screen and (min-width: 769px) {
  .iframe-container {
    width: 100%;
    max-width: 91%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: 9px;
  }
}
@media screen and (max-width: 768px) {
  .iframe-container {
    width: 100%;
    max-width: calc(100vw - 90px);
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border-radius: 9px;
  }
}
