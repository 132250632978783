.adu-chat-main {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 76px);
  max-width: 926px;
}

.autosize-textarea {
  width: 100%;
  max-height: 250px;
  min-height: 30px;
  height: 25px;
  transition: width 0.25s;
  resize: none;
  margin: 1rem 1rem 1rem 2rem;
  color: #504d3f;
}
@media screen and (min-width: 768px) {
  .autosize-textarea,
  .autocomplete-container > input {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .autosize-textarea .autocomplete-container > input {
    font-size: 14px;
  }
}

.autosize-textarea:focus {
  outline: none;
}
.autosize-textarea::placeholder {
  color: #999999;
}

.keyboard-active {
  height: calc(100vh - 50px) !important;
}

@media (max-width: 767px) {
  .adu-bot-container {
    bottom: 1rem;
  }
  .autosize-textarea {
    margin: 0.7rem 0.7rem 0.7rem 1rem !important;
  }
}

.aduchat-send-btn.hovered:hover {
  filter: brightness(0.95);
}

.autocomplete-container {
  width: 100%;
}
.autocomplete-container > input::placeholder {
  color: #999999;
}
.autocomplete-container > input {
  color: #504d3f;
}

.autocomplete-container > input:focus {
  outline: none;
}

.custom-map-style {
  max-height: 491px;
}

@media screen and (max-width: 767px) {
  .custom-map-style {
    max-height: 300px;
  }
}

.custom-element-gradient {
  border: 1.5px solid #f5e5db;
}

.custom-element-gradient:hover,
.custom-element-gradient:focus,
.custom-element-gradient:focus-within {
  border: 1.5px solid transparent !important;
  background: linear-gradient(to right, white, white),
    linear-gradient(
      90.55deg,
      #ffc200 -3.45%,
      #f5a623 24.53%,
      #965df7 63.27%,
      #6d7afb 104.16%
    ) !important;
  background-clip: padding-box, border-box !important;
  background-origin: padding-box, border-box !important;
}

@media screen and (min-width: 769px) {
  .image-crop-container {
    display: flex;
    flex-direction: column;
    aspect-ratio: 16 / 9;
    width: 100%;
    height: 100%;
    border-radius: 9px;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .image-crop-container {
    display: flex;
    flex-direction: column;
    aspect-ratio: square;
    width: 100%;
    height: 350px;
    border-radius: 9px;
    overflow: hidden;
  }
}
